import HttpHelper from "../../helpers/httpHelper";
require("dotenv").config();
const headerInfo = {};

class PlanAPI {
  static async GetPlans() {
    let url = process.env.REACT_APP_API_URL + "/plans";
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async GetPlanDetail(req) {
    let url =
      process.env.REACT_APP_API_URL +
      `/plans/illustration/${req.id}?amount=${req.amount}&startDate=${req.date}`;
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async CreatePlanMember(req) {
    let url = process.env.REACT_APP_API_URL + "/member/plans?code=" + req.code;
    return HttpHelper.httpRequest(url, "POST", headerInfo, req);
  }

  static async UnStackPlanMember(req) {
    let url =
      process.env.REACT_APP_API_URL +
      "/contract/unstack/" +
      req.id +
      "?code=" +
      req.code;
    return HttpHelper.httpRequest(url, "POST", headerInfo, req);
  }

  static async GetPlanMember() {
    let url = process.env.REACT_APP_API_URL + "/member/plans";
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async GetTeamPlanMember() {
    let url = process.env.REACT_APP_API_URL + "/member/team/plans";
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async WithdrawUnStackCoins(req) {
    let url =
      process.env.REACT_APP_API_URL +
      "/planmember/withdraw/" +
      req.id +
      "?month=" +
      req.month +
      "&code=" +
      req.code;
    return HttpHelper.httpRequest(url, "POST", headerInfo);
  }
}

export default PlanAPI;
