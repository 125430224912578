import { call, put, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import * as PlanActions from "./plan.actions";
import PlanAPI from "./plan.api";
import { PlanActionTypes } from "./plan.model";

export function* handlePlans() {
  try {
    const response = yield call(PlanAPI.GetPlans);
    yield put(PlanActions.fetchPlansSuccess(response));
  } catch (e) {
    yield put(PlanActions.fetchPlansFailure(e));
  }
}

export function* handleDetail(action) {
  try {
    const response = yield call(PlanAPI.GetPlanDetail, action.payload);
    yield put(PlanActions.fetchPlanSuccess(response));
  } catch (e) {
    yield put(PlanActions.fetchPlanFailure(e));
  }
}

export function* handleCreate(action) {
  try {
    const response = yield call(PlanAPI.CreatePlanMember, action.payload);
    yield put(PlanActions.createPlanMemberSuccess(response));
    Swal.fire({
      icon: "success",
      text: "Joined Plan",
      text: "Joined to the plan successfully",
    });
  } catch (e) {
    yield put(PlanActions.createPlanMemberFailure(e));
  }
}

export function* handleUnStack(action) {
  try {
    const response = yield call(PlanAPI.UnStackPlanMember, action.payload);
    yield put(PlanActions.unStackPlanMemberSuccess(response));
    Swal.fire({
      icon: "success",
      text: "UnStack Plan",
      text: "Your Plan unstacked successfully",
    });
  } catch (e) {
    yield put(PlanActions.unStackPlanMemberFailure(e));
  }
}

export function* fetchMemberPlans() {
  try {
    const response = yield call(PlanAPI.GetPlanMember);
    yield put(PlanActions.fetchPlanMemberSuccess(response));
  } catch (e) {
    yield put(PlanActions.fetchPlanMemberFailure(e));
  }
}

export function* handleUnStackWithdraw(action) {
  try {
    const response = yield call(PlanAPI.WithdrawUnStackCoins, action.payload);
    yield put(PlanActions.withdrawUnStackSuccess(response));
    Swal.fire({
      icon: "success",
      text: "Withdraw UnStack Tokens",
      text: "Tokens withdraw to your withdraw address",
    }).then((xt) => {
      if (xt.isConfirmed) {
        document.location.reload();
      }
    });
  } catch (e) {
    yield put(PlanActions.withdrawUnStackFailure(e));
  }
}

export function* PlanSaga() {
  yield takeEvery(PlanActionTypes.FETCH_PLAN_REQUEST, handleDetail);
  yield takeEvery(PlanActionTypes.FETCH_PLANS_REQUEST, handlePlans);
  yield takeEvery(PlanActionTypes.CREATE_PLAN_MEMBER_REQUEST, handleCreate);
  yield takeEvery(PlanActionTypes.FETCH_PLAN_MEMBER_REQUEST, fetchMemberPlans);
  yield takeEvery(PlanActionTypes.UNSTACK_PLAN_MEMBER_REQUEST, handleUnStack);
  yield takeEvery(
    PlanActionTypes.UNSTACK_WITHDRAW_REQUEST,
    handleUnStackWithdraw
  );
}
