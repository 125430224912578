import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import {
  AddCircle,
  Close,
  CloudDownload,
  Description,
  List,
  OpenInNew,
} from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import _ from "underscore";
import { downloadIllustrationReport } from "../../helpers/PDFGenerator";
import { getTransactionUrl } from "../../helpers/utility";
import {
  unStackPlanMemberRequest,
  withdrawUnStackRequest,
} from "../../store/plan/plan.actions";
import { getPlanMembers, getPlans } from "../../store/plan/plan.selector";
import { getWalletInformation } from "../../store/user/user.selector";
import VerifyPin from "../verify/verifyPin";

export default function MyPlanDetail(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedMonth, setSelectedMonth] = useState("");
  const [isStacked, setStacked] = useState(false);
  const [isWithdraw, setWithdraw] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);

  const { plans, planMembers, walletInfo } = useSelector((state) => {
    return {
      plans: getPlans(state),
      planMembers: getPlanMembers(state),
      walletInfo: getWalletInformation(state),
    };
  }, []);

  let currency = "USDT";
  let total = 0;
  let reinvest = 0;
  return (
    <Grid container spacing={2}>
      <Dialog open={acknowledge} maxWidth="sm" fullWidth>
        <DialogContent>
          <VerifyPin
            onClose={() => {
              setAcknowledge(false);
              setSelectedMonth("");
              setWithdraw(false);
            }}
            isNotVerify={true}
            onComplete={(code) => {
              if (isWithdraw) {
                dispatch(
                  withdrawUnStackRequest({
                    id: selectedPlan._id,
                    month: selectedMonth,
                    code : code
                  })
                );
              } else {
                dispatch(
                  unStackPlanMemberRequest({
                    id: selectedPlan._id,
                    code: code,
                  })
                );
              }
              setAcknowledge(false);
              setSelectedMonth("");
              setWithdraw(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={() => {
          setStacked(false);
        }}
        open={isStacked}
      >
        <DialogTitle>
          UnStack Token Details
          <Close
            className="float-right"
            onClick={() => {
              setStacked(false);
              setSelectedPlan({});
            }}
          />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <table className="table table-striped mt-2">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>

                <th>Savings</th>
                <th>Withdraw Amount</th>
                <th>Fees</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {selectedPlan.unStackReturns &&
                selectedPlan.unStackReturns.map((tx) => {
                  total = total + parseFloat(tx.amount);
                  reinvest = reinvest + parseFloat(tx.reinvestment) / 3;
                  return (
                    <tr>
                      <td>{tx.month}</td>
                      <td>{tx.date}</td>
                      <td>
                        {(parseFloat(tx.reinvestment) / 3).toFixed(3)} T369
                      </td>

                      <td>{tx.amount} T369</td>
                      <td>
                        {((parseFloat(tx.amount) * 3) / 100).toFixed(3)} T369
                      </td>
                      <td>
                        <Button
                          size="small"
                          variant={tx.status === "Paid" ? "text" : "contained"}
                          color="primary"
                          onClick={() => {
                            if (tx.status === "Pending") {
                              setWithdraw(true);
                              setAcknowledge(true);
                              setSelectedMonth(tx.month);
                            }
                            if (tx.status === "Paid") {
                              window.open(
                                getTransactionUrl("BNB") + tx.withdrawHash,
                                "_blank"
                              );
                            }
                          }}
                          disabled={
                            moment(tx.date, "DD-MM-YYYY").diff(
                              moment(),
                              "second"
                            ) > 0
                          }
                        >
                          {moment(tx.date, "DD-MM-YYYY").diff(
                            moment(),
                            "second"
                          ) > 0
                            ? tx.status
                            : tx.status !== "Paid"
                            ? "Withdraw Now"
                            : "Paid"}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th></th>
                <th>Total</th>
                <th>{reinvest.toFixed(3)} T369</th>
                <th>{total.toFixed(3)} T369</th>
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth="md" fullWidth open={open}>
        <DialogTitle>
          Plan Details{" "}
          <Close
            className="float-right"
            onClick={() => {
              setOpen(false);
            }}
          />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <h4 className="my-1">Plan Summary</h4>
          {selectedPlan && (
            <table className="table table-striped mt-2">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Reward</th>
                  <th>Net Reward</th>
                  <th>Reinvesment</th>
                  <th>Liquidity</th>
                  <th>Gas Fees</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {selectedPlan.returns &&
                  selectedPlan.returns.map((s, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{s.date}</td>
                        <td>
                          {s.amount} {currency}
                        </td>
                        <td>
                          {s.interest} {currency}
                        </td>
                        <td>
                          {s.withdrawalAmount} {currency}
                        </td>
                        <td>
                          {s.reinvest} {currency}
                        </td>
                        <td>
                          {s.liquidity} {currency}
                        </td>
                        <td>
                          {s.adminFees} {currency}
                        </td>
                        <td>{s.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
          <Divider />
          <h4 className="my-1">Payouts</h4>
          {selectedPlan && selectedPlan.payouts && (
            <table className="table table-striped mt-2">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Reward</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {selectedPlan.payouts &&
                  selectedPlan.payouts.map((s, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{s.type}</td>
                        <td>{moment(s.createdAt).format("LLL")}</td>
                        <td>
                          {s.amount} {currency}
                        </td>
                        <td>{s.createdBy}</td>
                        <td>{s.memberId}</td>
                        <td>{s.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {selectedPlan.contract && selectedPlan.contract[0] && (
              <a
                target="_blank"
                href={
                  getTransactionUrl("BNB") +
                  selectedPlan.contract[0].transactionHash
                }
              >
                Transaction Hash <OpenInNew color="primary" />
              </a>
            )}
            <Button
              variant="contained"
              startIcon={<CloudDownload />}
              color="secondary"
              onClick={() => {
                downloadIllustrationReport(selectedPlan.returns);
              }}
            >
              Download
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Grid item md={4} lg={4} sm={12} xs={12}>
        <Card
          onClick={() => {
            props.onOpen();
          }}
          variant="outlined"
          style={{ minHeight: 250 }}
        >
          <CardContent>
            <Box
              display="flex"
              flexDirection={"column"}
              height={250}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <AddCircle style={{ fontSize: 64 }} color="secondary" />
              <p className="text-secondary text-bold">Create Plan</p>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {planMembers &&
        planMembers.map((s, index) => {
          let planDetail = _.find(plans, (z) => z._id === s.planId);
          let duration = moment(s.maturityDate).diff(
            moment(s.createdAt),
            "day"
          );
          let completed =
            s.status === "Approved"
              ? moment().diff(moment(s.createdAt), "day")
              : duration;
          completed = parseInt((completed * 100) / duration);
          return (
            <Grid item md={4} lg={4} sm={12} xs={12}>
              <Card variant="outlined">
                <CardHeader
                  avatar={<List />}
                  title={planDetail && planDetail.planName}
                  subheader={planDetail && planDetail.duration + " months"}
                  action={
                    <Button
                      size="small"
                      onClick={() => {
                        setOpen(true);
                        setSelectedPlan(s);
                      }}
                      className="mt-3"
                      variant="contained"
                      color="secondary"
                      startIcon={<Description />}
                    >
                      Details
                    </Button>
                  }
                />
                <Divider />
                <CardContent style={{ padding: 6 }}>
                  <ul className="stripe-ul">
                    <li>
                      <span>Joined Date :</span>
                      <span>{moment(s.createdAt).format("lll")}</span>
                    </li>
                    <li>
                      <span>
                        {moment().diff(moment(s.maturityDate), "second") < 1
                          ? "Maturity Date"
                          : "Unstack Date"}{" "}
                        :
                      </span>
                      <span>{moment(s.maturityDate).format("ll")}</span>
                    </li>
                    <li>
                      <span>Duration : </span>
                      <span>{s.returns && s.returns.length} Months</span>
                    </li>
                    <li>
                      <span>Amount : </span>
                      <span>{s.amount} USDT</span>
                    </li>
                    <li>
                      <span>Tokens : </span>
                      <span>{s.token} T369</span>
                    </li>
                  </ul>
                  {moment().diff(moment(s.maturityDate), "second") > 1 ? (
                    <Button
                      fullWidth
                      className="bg-success text-white mt-2"
                      variant="contained"
                      onClick={() => {
                        if (s.status === "UnStack") {
                          setStacked(true);
                          setSelectedPlan(s);
                        } else {
                          if (parseFloat(walletInfo.coinBalance) > 0.0017) {
                            setSelectedPlan(s);
                            setAcknowledge(true);
                          } else {
                            Swal.fire({
                              title: "InSufficient Balance",
                              text:
                                "Your Current Wallet Balance is " +
                                walletInfo.coinBalance +
                                " BNB. Please have atleast 000171 BNB to proceed",
                            });
                          }
                        }
                      }}
                    >
                      {s.status === "UnStack"
                        ? "Unstack Returns"
                        : "Start UnStacking"}
                    </Button>
                  ) : (
                    <LinearProgress
                      className="mt-2"
                      color="secondary"
                      style={{ height: 20 }}
                      value={completed}
                      variant="determinate"
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
}
