import { PlanActionTypes } from "./plan.model";
require("dotenv").config();

export const initialState = {
  plan: [],
  plans: [],
  planMembers: [],
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PlanActionTypes.FETCH_PLANS_REQUEST:
    case PlanActionTypes.CREATE_PLAN_MEMBER_REQUEST:
    case PlanActionTypes.UNSTACK_PLAN_MEMBER_REQUEST:
    case PlanActionTypes.UNSTACK_WITHDRAW_REQUEST:
    case PlanActionTypes.FETCH_PLAN_MEMBER_REQUEST:
    case PlanActionTypes.FETCH_PLAN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case PlanActionTypes.FETCH_PLAN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        plan: action.payload,
      };
    }

    case PlanActionTypes.FETCH_PLANS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        plans: action.payload,
      };
    }

    case PlanActionTypes.FETCH_PLAN_MEMBER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        planMembers: action.payload,
      };
    }

    case PlanActionTypes.CREATE_PLAN_MEMBER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        planMembers: [...state.planMembers, action.payload],
      };
    }

    case PlanActionTypes.UNSTACK_WITHDRAW_SUCCESS:
    case PlanActionTypes.UNSTACK_PLAN_MEMBER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        planMembers: state.planMembers.map((tx) => {
          if (tx._id === action.payload._id) {
            return (tx = action.payload);
          }
          return tx;
        }),
      };
    }

    case PlanActionTypes.UNSTACK_WITHDRAW_FAILURE:
    case PlanActionTypes.FETCH_PLAN_FAILURE:
    case PlanActionTypes.CREATE_PLAN_MEMBER_FAILURE:
    case PlanActionTypes.UNSTACK_PLAN_MEMBER_FAILURE:
    case PlanActionTypes.FETCH_PLAN_MEMBER_FAILURE:
    case PlanActionTypes.FETCH_PLANS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as PlanReducer };

